import React, { useState, useEffect } from 'react'
import { Text } from './Language';
import LoginModal from "./LoginModal";
import { ReactComponent as UserIcon } from './assets/pic/user-icon.svg';
import { ReactComponent as HeadquarterIcon } from './assets/pic/headquarter.svg';
import { ReactComponent as ShippingIcon } from './assets/pic/shipping.svg';
import { VERSION } from './components/Constants';

export default function UserProfile(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setData(JSON.parse(sessionStorage.getItem('user')))
    }, [showModal]);

    function showUserProfile() {
        setShowModal(true)

    };

    const parentHandleToken = (isLogged) => {

        props.setAppToken(isLogged);
    };


    return (
        <>

            <div className="self-center text-white cursor-pointer text-xxs lg:text-sm"
                onClick={() => showUserProfile()}>
                {data
                    ? <div className='flex flex-row h-8 items-center w-20 lg:w-48 justify-end'>
                        <UserIcon
                            className="w-6 mr-2 fill-white stroke-white"
                        />
                        <div>{data[0].userName}</div>
                    </div>
                    : null}
            </div>
            {showModal ? (
                <>
                    <div
                        className="text-slate-900 top-12 fade In justify-end w-full
                        flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none 
                        focus:outline-none"
                    >
                        <div className="relative  flex w-96 h-[550px] justify-end">
                            {/*content*/}
                            <div className="border-2 border-company-primary-color shadow-lg relative flex flex-col
                             w-full h-full bg-white outline-none focus:outline-none">

                                {/*header*/}
                                <div className="flex items-start justify-between p-2 border-b
                                 border-solid border-slate-200 rounded-t">
                                    <h3 className="text-md font-semibold">
                                        <Text tid="userProfile" />
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black
                                         opacity-50 float-right text-xl leading-none font-semibold
                                          outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black h-6 w-6 text-xl
                                         block outline-none focus:outline-none">
                                            &#10006;
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className='flex flex-row w-full justify-between px-6'>
                                    <div></div>


                                </div>
                                <div className='flex flex-row w-full h-full overflow-auto'>
                                    {!data ? <div className="flex w-96  h-96 items-center 
                                    justify-center"><Text tid="NoData" /></div>:null}

                                    {data ?
                                        <div className='flex flex-row w-full overflow-auto h-full'>

                                            {data
                                                ? <div className=' w-full h-full grid grid-cols-1 content-between'>
                                                    <div className='flex gap-2 flex-col'>
                                                        <div className='flex flex-row bg-company-primary-color-100 p-2 gap-2' >
                                                            <UserIcon className="w-6 h-6 fill-white stroke-company-primary-color" />
                                                            <div>
                                                                <div className='font-bold '>
                                                                    {data[0].userName}
                                                                </div>
                                                                <div>
                                                                    {sessionStorage.getItem('email')}
                                                                </div>
                                                                <div className='text-company-primary-color'>
                                                                    {sessionStorage.getItem('customerCode')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-row bg-company-primary-color-100 p-2 gap-2' >
                                                        <HeadquarterIcon className="w-6 h-6 stroke-company-primary-color"/>
                                                            <div>
                                                                <div className='font-bold '>
                                                                    {data[0].erpCustomerName1}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpCustomerAddress1}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpCustomerCityPostalCode}&nbsp;
                                                                    {data[0].erpCustomerCityName}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpCustomerCountryName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-row bg-company-primary-color-100 p-2 gap-2' >
                                                        <ShippingIcon className="w-6 h-6 fill-company-primary-color"/>
                                                            <div>
                                                                <div className='font-bold '>
                                                                    {data[0].erpShippingAdrName1}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpShippingAdrName2}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpShippingAdrAddress1}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpShippingAdrAddress2}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpShippingAdrPostalCode}&nbsp;
                                                                    {data[0].erpShippingAdrCityName}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpShippingAdrCountryCode}&nbsp;
                                                                    {data[0].erpShippingAdrCountryName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='h-6'></div>
                                                    <div className='w-full gap-2 flex flex-col'>
                                                        
                                                        <div className='bg-company-primary-color-100 p-2 font-bold cursor-pointer'
                                                        >
                                                            <LoginModal
                                                                parentHandleToken={parentHandleToken}
                                                                onLogoutDeleteItemsAndCategories={() => props.onLogoutDeleteItemsAndCategories()}
                                                            />
                                                        </div>
                                                        <div className='bg-gray-200 flex justify-between p-2'>
                                                            <p className='text-very-small'>
                                                                Engineered by FALKE Bilgi Teknolojileri Ltd. Şti.
                                                            </p>
                                                            <p className='text-very-small'>
                                                            { VERSION }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            :null}
                                        </div>:null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}
