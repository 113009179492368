import React from 'react'
import { Text } from './Language';
import { URL as NEWURL } from './components/Constants';
import { VENDORID as NEWVENDORID } from './components/Constants';
import { ReactComponent as DeleteIcon } from './assets/pic/delete.svg';

export default function SlipDeleteAllLines(props) {
    const [showModal, setShowModal] = React.useState(false);

    function deleteAllLines(actualSlipDelete) {
    
        setShowModal(false)
        const url = NEWURL;

        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json' },
            body: JSON.stringify({
                "serviceId": 2014, //Slip DeleteAllLines
                "token": sessionStorage.getItem('token'),
                "slipRef": sessionStorage.getItem('slipRef'),
                "vendorId": NEWVENDORID
            })
        };

        const fetchData = async () => {
            try {
                const response = await fetch(url, requestOptions);
                const json = await response.json();
                if (json.resCode === 200) props.onSlipDeleteAllLines()
            } catch (error) {
                console.log("error", error);
            }
        };

        if(actualSlipDelete) fetchData();
    }

    return (
        <>
            <button
                className="flex items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                type="button"  onClick={() => setShowModal(true)}
                
            >
                <DeleteIcon className="w-6 h-6 fill-white" />
            </button>
            {showModal ? (

                <>
                    <div
                        className="fade In justify-center items-center flex overflow-x-hidden 
        overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div
                            className="relative w-auto my-6 mx-auto max-w-3xl"
                        >
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                            >
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                                >
                                    <h3
                                        className="text-lg font-semibold"
                                    >
                                        <Text tid="SlipDelete" />
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-50 float-right text-3xl 
                        leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span
                                            className="bg-transparent text-black h-6 w-6 text-xl block outline-none focus:outline-none"
                                        >
                                            &#10006;
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div
                                    className="relative p-6 flex-auto"
                                >
                                    <div
                                        className="my-4 text-slate-500 text-lg leading-relaxed">
                                        <Text tid="SlipDeleteText" />
                                        <div className='flex flex-row justify-between mt-6 gap-2'>
                                            <button
                                                className="flex items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                                type="button"
                                                onClick={() => deleteAllLines(0)}
                                            >
                                                <Text tid="No" />
                                            </button>
                                            <button
                                                className="flex items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                                type="button"
                                                onClick={() => deleteAllLines(1)}
                                            >
                                                <Text tid="Yes" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="opacity-50 fixed inset-0 z-40 bg-black"
                    >
                    </div>
                </>

            )
                : null
            }
        </>
    )
}
