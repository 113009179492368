import toGermanFloat from './toGermanFloat';
import { DIGITS } from './components/Constants';

export default function roundDigits(number) {
    var factor = '1';
    while (factor.length <= DIGITS) { factor = factor + '0'; }
    number = Math.round(number * factor) / factor;
    number = number?.toString().split('.');
    if (number[1] === undefined) number[1] = '0';
    while (number[1]?.length < DIGITS) { number[1] = number[1] + '0'; }

    return toGermanFloat(number[0] + '.' + number[1])
}