import { URL as NEWURL } from './components/Constants';
import { VENDORID as NEWVENDORID } from './components/Constants';

export default function useFetchCampaignPrice(obj) {

    const requestOptions = {
        method: "POST",
        headers: {
            'Accept': 'application/json',
        },
        body: JSON.stringify({
            "serviceId": 1407, //service for Slip.Lines.Post
            "token": sessionStorage.getItem('token'),
            "slipRef": parseInt(sessionStorage.getItem('slipRef')),
            "erpItemRef": obj.itemRef,
            "erpItemUnitARef": obj.itemUnitARef,
            "erpItemAmount": 0,
            "erpItemPrice": obj.itemPrice,
            "erpDiscRate": sessionStorage.getItem('discRate') ? parseFloat(sessionStorage.getItem('discRate')) : 0,
            "campaignPrice": obj.itemCampaignPrice,
            "slipLineExpl": "",
            "vendorId": NEWVENDORID
        })
    }

    const fetchData = async () => {
        try {
            const response = await fetch(NEWURL, requestOptions);
            const json = await response.json();
            if (json?.resCode === 200) {
                console.log(json)
                return json;
            }
            else return;
        } catch (error) {
            console.log("error", error);
        }
    };


    return fetchData();
}