import {URL as NEWURL} from './components/Constants';
import {VENDORID as NEWVENDORID} from './components/Constants';

export default function useSendDataToAPI(serviceId, item, favorite) {
    const url = NEWURL;  

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": serviceId,
            "token": sessionStorage.getItem('token'),
            "slipRef": sessionStorage.getItem('slipRef'),
            "erpItemRef": item.itemRef,
            "erpItemUnitARef": item.itemUnitARef,
            "erpItemAmount": item.itemAmount ? item.itemAmount : 0,
            "erpItemPrice": item.itemPrice,
            "erpDiscRate": sessionStorage.getItem('discRate')
                ? parseFloat(sessionStorage.getItem('discRate'))
                : 0,
            "campaignPrice": item.itemCampaignPrice,
            "slipLineExpl": "",
            "favorite": favorite,
            "vendorId": NEWVENDORID
        })
    };

    const fetchData = async () => {
        try {
            const response = await fetch(url, requestOptions);
            const json = await response.json();
            if (json.resCode === 200)
                return json;
            else return;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}
