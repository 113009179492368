import React from 'react'
import {URL as NEWURL} from './components/Constants';
import {VENDORID as NEWVENDORID} from './components/Constants';
import { ReactComponent as DeleteIcon } from './assets/pic/delete.svg';

export default function SlipDelete(props) {

    function handleOnClick() {

        
        const url = NEWURL;  

        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json' },
            body: JSON.stringify({
                "serviceId": 2013, //Slip Delete
                "token": sessionStorage.getItem('token'),
                "slipRef": props.slipRef,
                "vendorId": NEWVENDORID
            })
        };

        const fetchData = async () => {
            try {
                const response = await fetch(url, requestOptions);
                const json = await response.json();
                 if(json.resCode === 200) props.slipDeleteData(props.slipRef)
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();

    }

    return (
        <>
            <button
                className="flex items-center fill-white justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                type="button"
                onClick={() => handleOnClick()}
            >
                <DeleteIcon className="w-6 h-6 fill-white"/>
            </button>
        </>
    )
}