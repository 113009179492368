import React from 'react'
import BlankBasket from "./BlankBasket";
import Counter from "./Counter";
import Favorite from "./Favorite";
import { ReactComponent as DiscountIcon } from './assets/pic/discount.svg';
import SlipDeleteAllLines from './SlipDeleteAllLines';
import SlipSave from './SlipSave';
import { Text } from './Language';
import roundDigits from "./roundDigits";
import { CURRENCY as NEWCURRENCY, DIGITS, IMAGEURL } from './components/Constants';
import OrderItems from "./OrderItems";
//import ItemModal from './ItemModal';

function DetailedBasket(props) {
    const discRate = sessionStorage.getItem('discRate');
    const itemsPrice = props.cartItems?.reduce((a, c) =>
        a + c.itemMainUnitVpe
        * (c.itemCampaignPrice ? c.itemCampaignPrice?.toFixed(DIGITS) : c.itemPrice?.toFixed(DIGITS))
        * (c.itemCampaignPrice ? 1 : (1 - (!Number.isNaN(discRate) && discRate > 0 ? discRate / 100 : 0)))
        * c.itemAmount, 0);

    const itemsPriceTax = props.cartItems?.reduce((a, c) =>
        a + c.itemMainUnitVpe
        * (c.itemCampaignPrice ? c.itemCampaignPrice?.toFixed(DIGITS) : c.itemPrice?.toFixed(DIGITS))
        * (c.itemCampaignPrice ? 1 : (1 - (!Number.isNaN(discRate) && discRate > 0 ? discRate / 100 : 0)))
        * (1 + c.itemVat / 100)
        * c.itemAmount, 0);

   // const [showModalForItem, setShowModalForItem] = React.useState(false);
    const [Expl1, setExpl1] = React.useState('');
    const [Expl2, setExpl2] = React.useState('');

    React.useEffect(() => {    
        const storedExpl1 = sessionStorage.getItem('Expl1');
        const storedExpl2 = sessionStorage.getItem('Expl2');
        if (storedExpl1) {
            console.log(storedExpl1)
            setExpl1(storedExpl1);
        }
        if (storedExpl2) {
            setExpl2(storedExpl2);
        }
    }, [sessionStorage.getItem('Expl1'), sessionStorage.getItem('Expl2')]);

    const handleChangeExpl1 = (event) => {
        setExpl1(event.target.value);
        sessionStorage.setItem('Expl1', event.target.value);
    };

    const handleChangeExpl2 = (event) => {
        setExpl2(event.target.value);
        sessionStorage.setItem('Expl2', event.target.value);
    };

    const handleDelete = () => {
        sessionStorage.removeItem('Expl1');
        sessionStorage.removeItem('Expl2');
        setExpl1('');
        setExpl2('');
    };


    return (<>
        {props.detailedBasket
            && sessionStorage.getItem('token') ? (

            <>

                <div
                    className="text-slate-600 h-full fade In justify-center items-center flex overflow-hidden fixed inset-0 z-50 
                    outline-none focus:outline-none"
                >
                    <div className="flex w-5/6 my-6 mx-auto max-h-[80%] ">
                        {/*content*/}
                        <div
                            className="overflow-auto border-0 rounded-lg shadow-lg
                             relative flex flex-col w-full bg-white p-2 ">
                            {/*header*/}
                            <div
                                className="sticky top-0 flex flex-row gap-3 justify-between 
                                p-1 border-b border-solid border-slate-200 rounded-t w-full h-18 z-10 "
                            >

                                <button
                                    className="border-0 text-black flex items-center h-6
                                        opacity-50  text-3xl  font-semibold
                                         outline-none focus:outline-none"
                                    onClick={() => props.ShowDetailedBasket(false)}
                                >
                                    <span
                                        className="bg-transparent text-black h-6 w-6 text-xl flex items-centers justify-center
                                         outline-none focus:outline-none"
                                    >
                                        &#10006;
                                    </span>
                                </button>
                                <h3 className="text-lg font-semibold text-slate-700">
                                    <Text tid="ShoppingCart" />
                                </h3>
                                <div className='ml-auto flex flex-row gap-5 z-50'>

                                    <SlipDeleteAllLines
                                        onSlipDeleteAllLines={() => { props.onSlipDeleteAllLines(); props.ShowDetailedBasket(false); handleDelete(); }}

                                    />
                                    <SlipSave
                                        onSlipDeleteAllLines={() => { props.onSlipDeleteAllLines(); props.ShowDetailedBasket(false); handleDelete(); }}
                                    />
                                    <OrderItems
                                        cartItems={props.cartItems}
                                        onSlipDeleteAllLines={() => { props.onSlipDeleteAllLines(); props.ShowDetailedBasket(false); handleDelete(); }}
                                        showToast={(type) => props.showToast(type)}
                                    />

                                </div>
                            </div>
                            {/*body*/}
                            <div className='flex flex-row justify-between items-center w-full bg-company-primary-color-100 p-1 rounded-t-lg'>
                                <div className=' flex flex-col justify-between items-center w-1/2 gap-1'>
                                    <div className='w-full'>
                                        <label
                                            htmlFor="Explanation1" className=""
                                        >
                                            <Text tid="Explanation1" />:
                                        </label>

                                        <input
                                            id="Explanation1"
                                            name="Explanation1"
                                            type="text"
                                            value={Expl1}
                                            onChange={handleChangeExpl1}
                                            autoComplete="Explanation1"
                                            maxLength={51}
                                            className="appearance-none mx-1 rounded w-4/5 h-8 border-0"                                           
                                        />
                                    </div>
                                    <div  className='w-full'>
                                        <label
                                            htmlFor="Explanation2" className=""
                                        >
                                            <Text tid="Explanation2" />:
                                        </label>

                                        <input
                                            id="Explanation2"
                                            name="Explanation2"
                                            type="text"
                                            value={Expl2}
                                            onChange={handleChangeExpl2}
                                            autoComplete="Explanation2"
                                            maxLength={51}                                          
                                            className="appearance-none mx-1 rounded w-4/5 h-8 border-0"  
                                        />
                                    </div>
                                </div>


                                <div>
                                    <Text tid="NetAmount" />
                                    :
                                </div>
                                <div>
                                    <strong>

                                        {roundDigits(itemsPrice)}  {NEWCURRENCY}
                                    </strong>
                                </div>
                                <div>
                                    <strong>
                                        <Text tid="TotalAmount" />
                                        :
                                    </strong>
                                </div>
                                <div>
                                    <strong>

                                        {roundDigits(itemsPriceTax)}  {NEWCURRENCY}
                                    </strong>
                                </div>

                            </div>
                            <div className=' flex flex-row w-full overflow-auto h-full'>
                                {
                                    props.cartItems.length === 0
                                        ? <div className="h-96">
                                            <BlankBasket />
                                        </div>
                                        : null
                                }
                                {
                                    props.cartItems.length !== 0 ?
                                        <div
                                            className='relative flex flex-col w-full z-0 h-full '
                                        >
                                            <table
                                                className='overflow-auto w-full border-separate
                                            border border-slate-400 text-center table-auto text-slate-600'
                                            >
                                                <thead className='sticky top-0 bg-white'>
                                                    <tr>
                                                        <th className="border border-slate-300"><Text tid="ProductPicture" /></th>
                                                        <th className="border border-slate-300"><Text tid="ProductCode" /></th>
                                                        <th className="border border-slate-300"><Text tid="ProductName" /></th>
                                                        <th className="border border-slate-300"><Text tid="ProductCount" /></th>
                                                        <th className="border border-slate-300"><Text tid="Unit" /></th>
                                                        <th className="border border-slate-300"><Text tid="PackagingUnit" /></th>
                                                        <th className="border border-slate-300"><Text tid="PiecePrice" /></th>
                                                        <th className="border border-slate-300"><Text tid="NetAmount" /></th>
                                                        <th className="border border-slate-300"><Text tid="VAT" /></th>
                                                        <th className="border border-slate-300"><Text tid="Amount" /></th>
                                                        <th className="border border-slate-300">%</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='overflow-y-auto '>
                                                    {
                                                        props.cartItems
                                                            ? props.cartItems.map((record, index) => {
                                                                return (
                                                                    <tr
                                                                        key={index}
                                                                        className="odd:bg-white even:bg-slate-200"
                                                                    >
                                                                        <td>
                                                                            <div className="h-12 relative ">
                                                                                <img
                                                                                    alt=''
                                                                                 
                                                                                    src={IMAGEURL + "/thumbs/TH"
                                                                                        + record.itemCode
                                                                                        + ".jpg"}
                                                                                    onError={(e) => {
                                                                                        e.currentTarget.onerror = null; // prevents looping
                                                                                        e.currentTarget.src = "noImage.jpg";
                                                                                    }} className="w-full h-full object-contain relative z-0" />
                                                                                {record.itemCampaign
                                                                                    ? <div
                                                                                        className="flex flex-row items-center text-red-600 absolute bottom-0 right-2 w-5 h-5"
                                                                                    >
                                                                                        <DiscountIcon
                                                                                            className="w-5 h-5"
                                                                                        />
                                                                                    </div>
                                                                                    : null
                                                                                }</div>
                                                                        </td>
                                                                        <td>
                                                                            {record.itemCode}
                                                                        </td>
                                                                        <td className='text-start'>
                                                                            {record.itemName1}
                                                                        </td>
                                                                        <td className='flex flex-row justify-center items-center h-12 '>
                                                                            <div className="flex">
                                                                                <Favorite
                                                                                    className="shadow-md shadow-slate-400"
                                                                                    basket={true}
                                                                                    itemRef={record.itemRef}
                                                                                    itemFavorite={record.itemFavorite}
                                                                                    item={record}
                                                                                    onAddAllFavorites={props.onAddAllFavorites}
                                                                                    onRemoveAllFavorites={props.onRemoveAllFavorites}
                                                                                />
                                                                            </div>
                                                                            <div className="flex w-24">
                                                                                <Counter
                                                                                    basket={true}
                                                                                    count={record.itemAmount}
                                                                                    onClickIncrement={() => props.onAdd(record)}
                                                                                    onClickDecrement={() => props.onRemove(record)}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {record.itemUnitCode}
                                                                        </td>
                                                                        <td>
                                                                            {record.itemMainUnitVpe}
                                                                        </td>
                                                                        <td>
                                                                            <div className={`${record.itemCampaignPrice
                                                                                ? "line-through"
                                                                                : sessionStorage.getItem("discRate")
                                                                                    ? ""
                                                                                    : ""
                                                                                } font-bold text-company-primary-color`}>
                                                                                {roundDigits(record.itemPrice)}
                                                                                &nbsp;{NEWCURRENCY}
                                                                            </div>
                                                                            {record.itemCampaignPrice
                                                                                ? <div className='text-red-600 font-bold '>
                                                                                    {record.itemCampaignPrice.toFixed(DIGITS)}&nbsp;{NEWCURRENCY}
                                                                                </div>
                                                                                : null
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                roundDigits(
                                                                                    (record.itemMainUnitVpe
                                                                                        * (record.itemCampaignPrice
                                                                                            ? 1
                                                                                            : (1 - discRate / 100))
                                                                                        * record.itemAmount
                                                                                        * (record.itemCampaignPrice
                                                                                            ? record.itemCampaignPrice.toFixed(DIGITS)
                                                                                            : record.itemPrice.toFixed(DIGITS))
                                                                                    ))
                                                                            }
                                                                            &nbsp;{NEWCURRENCY}
                                                                        </td>
                                                                        <td>
                                                                            {record.itemVat}&nbsp;%
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                roundDigits(
                                                                                    (record.itemMainUnitVpe
                                                                                        * (record.itemCampaignPrice
                                                                                            ? 1
                                                                                            : (1 - discRate / 100))
                                                                                        * record.itemAmount
                                                                                        * (record.itemCampaignPrice
                                                                                            ? record.itemCampaignPrice.toFixed(DIGITS)
                                                                                            : record.itemPrice.toFixed(DIGITS))
                                                                                        * (1 + record.itemVat / 100)
                                                                                    ))
                                                                            }
                                                                            &nbsp;{NEWCURRENCY}
                                                                        </td>
                                                                        <td className='text-red-600 font-bold'>
                                                                            {
                                                                                record.itemCampaignPrice
                                                                                    ? ((record.itemPrice - record.itemCampaignPrice) /
                                                                                        (record.itemPrice / 100)).toFixed(0)
                                                                                    : sessionStorage.getItem("discRate") > 0
                                                                                        ? JSON.parse(sessionStorage.getItem("discRate"))
                                                                                        : null
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                            ) : null
                                                    }
                                                </tbody>
                                            </table>

                                        </div> : null
                                }
                            </div>
                            <Text tid="RecordCount" />: {props.cartItems.length}
                        </div>
                    </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </>
        )
            : null
        }
    </>
    )
}

export default DetailedBasket