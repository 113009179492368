import React, { useState } from "react";
import { ReactComponent as FontAwesomeIcon } from './assets/pic/bars-solid.svg';
import logo from './assets/pic/logo.png';
import { ReactComponent as SearchIcon } from './assets/pic/search.svg';
import LanguageSelector from './LanguageSelector';
import "./styles.css";
import Order from "./Order";
import Bill from "./Bill";
//import useToken from './useToken';
import { Text } from './Language';
import UserProfile from "./UserProfile";
import LoginModal from "./LoginModal";

export default function Menu(props) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [term, setTerm] = useState('');

  const handleInputChange = (event) => {
    setTerm(event.target.value);
    props.search(event.target.value);
  };

  const clickHandler = () => {
    setMenuOpen(!menuOpen)
  }

  const parentHandleToken = (isLogged) => {

    props.setAppToken(isLogged);
  };

  const handleOpen = () => {
    props.WebCategory("Home", null)
  };

  return (
    <>
      <div
        className="font-normal text-white w-full flex flex-row bg-gradient-to-r from-menu-primary-color to-menu-secondary-color"
      >
        <nav
          className=" w-full  lg:px-3 items-center flex flex-row"
        >
          <div className="grid flex-none items-center content-center w-24 h-full lg:w-24">
            <img className="flex w-24 items-center justify-center lg:w-24" src={logo} alt="Logo" />
          </div>

          <div
            className="px-4 md:hidden flex-row w-full justify-between flex"
          >
            <button
              className="lg:hidden cursor-pointer text-xl leading-none px-3 py-1 
                border border-solid border-transparent rounded-lg bg-transparent 
                outline-none focus:outline-none"
              type="button"
              onClick={clickHandler}
            >
              <FontAwesomeIcon
                className="px-2 w-10 fill-white"
              />
            </button>
            <button
              className="cursor-pointer text-xl leading-none border border-solid 
                  border-transparent rounded-lg bg-transparent outline-none focus:outline-none"
              type="button"
              onClick={props.onClick}
            >
            </button>
          </div>
          <div
            className="flex font-bold flex-row px-5 py-1 justify-between w-full"
          >
            {sessionStorage.getItem("token")
              ?
              <>
                <button
                  onClick={handleOpen}
                  className="hidden md:flex w-20 2xl:w-32 h-10 m-1 items-center 
              justify-center  p-2
              text-sm font-bold text-white  hover:opacity-75"
                  href="#pablo"
                >
                  <Text tid="Home" />
                </button>

                <div
                  className="flex items-center relative w-56 xl:w-1/3"
                >
                 
                    <input
                      value={term} onChange={(event) => { handleInputChange(event) }}
                      type="search"
                      className="appearance-none form-control relative flex w-full  px-1 h-10
                 py-1.5 text-base font-normal text-slate-700 bg-white bg-clip-padding
                 border-2 border-white transition ease-in-out
                   m-0 focus:text-slate-700 focus:bg-white  focus:ring-0 focus:border-white
                   focus:outline-none"
                      placeholder=""
                      aria-label="Search"
                      aria-describedby="button-addon3"
                    />
                    <button
                      className="appearance-none inline-block px-4 py-2 border-2 border-white text-white h-10
                   font-medium text-xs leading-tight uppercase hover:bg-company-primary-color-100 absolute -right-14
                    hover:outline-none focus:outline-none focus:ring-0 focus:ring-transparent transition 
                    flex items-center justify-end focus:border-white focus:bg-transparent
                    duration-150 ease-in-out"
                      type="button"
                      id="button-addon3"
                      onClick={(event) => { event.preventDefault(); props.search(term); }}
                    >
                      <SearchIcon className="w-6 h-6 fill-white" />
                    </button>
                  </div>
                </>
             
              : <div></div>
            }

            <ul
              className="items-center xl:space-x-2 flex justify-end flex-row w-1/4 xl:w-1/3 ml-36
             lg:flex-row list-none"
            >
              {sessionStorage.getItem("token")
                ? <>
                  <li
                    className="nav-item hidden md:flex"
                  >
                    <Order onWait={props.onWait} onLoadSavedSlip={() => props.onLoadSavedSlip()} />
                  </li>
                  <li
                    className="nav-item hidden md:flex"
                  >
                    <Bill />
                  </li>   </>
                : null
              }
              {!sessionStorage.getItem("token")
                ? <>
                  <li
                    className="nav-item"
                  >
                    <LoginModal 
                    parentHandleToken={parentHandleToken}
                    onGetItems={() => props.onGetItems()}
                    onGetCategories={() => props.onGetCategories()}
                     />
                  </li>
                  <li>
                    <LanguageSelector menu="true" />
                  </li>
                </>
                : null
              }
              {sessionStorage.getItem('user')
                ?
                <li>
                  <UserProfile setAppToken={props.setAppToken} 
                  onLogoutDeleteItemsAndCategories={() => props.onLogoutDeleteItemsAndCategories()}/>
                </li>
                : null
              }
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}