import React from 'react'
import { Text } from './Language';

export default function AboutUs(props) {  

    return (
        <>
            <button
                className="flex items-center 
        justify-center "
                type="button"
                onClick={() => props.ShowAboutUs(true)}
            >
                <Text tid="Über uns" />
            </button>
            
        </>
    )
}