import React, { Component } from "react";
import { ReactComponent as FavIcon } from './assets/pic/heart.svg';
import { ReactComponent as NewIcon } from './assets/pic/new.svg';
import { ReactComponent as DiscountIcon } from './assets/pic/discount.svg';
import { ReactComponent as TickIcon } from './assets/pic/tick.svg';
import { ReactComponent as DiscoverIcon } from './assets/pic/discover.svg';
import { Text } from './Language';
import { IMAGEURL } from "./components/Constants";

class CategoryList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
      test: null,
      categories: [],
      buttonActive: null
    }
  }

  formatString = (link) => {
    let temp = link;
    temp = temp.replace(/\s+/g, '');
    return temp
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productWebCategory !== this.props.productWebCategory && this.props.productWebCategory === "Home") {
      this.setState({ test: null });
    }
  }

  handleOpen = (i, z) => {
    console.log(z)
    if (sessionStorage.getItem("items")) {
      this.setState({ buttonActive: this.props.WebCategory(i, z) });
      if (i) {
        if (this.state.test === i) {
          this.setState({ test: null });
        } else
          this.setState({ test: i });
      }

      console.log()
    }
  };

  render() {
    return (
      <>
        <div
          className="w-[30rem] pr-6 hidden md:flex flex-col text-company-primary-color"
        >
          <h2
            className="text-md font-medium my-2"
          >
            <Text tid="Categories" />
          </h2>
          <div
            className="flex flex-col w-full border-t-4 border-b-4 border-r-2 text-xxs overflow-y-auto border-company-primary-color-100"
          >
            <div
              className="p-1"
            >
              <div
                className="flex flex-col w-full  pb-5 pb-5"
              >
                <button
                  onClick={() => this.handleOpen("PickedProducts", "Deals")}
                  className="flex flex-row p-1 w-full"
                >
                  <div
                    className="flex  w-10 justify-center p-1"
                  >
                    <div>
                      <DiscountIcon
                        className=" w-8 h-8 bg-white border border-slate-400  rounded-lg"
                      />
                    </div>
                  </div>
                  <div
                    className={"flex w-full inline-flex w-full px-4 py-2 text-sm font-medium text-company-primary-color hover:bg-company-primary-color-100"
                      + (this.state.buttonActive === "Deals"
                        ? " bg-company-primary-color-100"
                        : " ")}
                  >
                    <p
                      className='text-left'
                    >
                      <Text tid="Sale" />
                    </p>
                  </div>
                </button>
                <button
                  onClick={() => this.handleOpen("PickedProducts", "New Products")}
                  className="flex flex-row p-1 w-full"
                >
                  <div
                    className="flex w-10 justify-center p-1"
                  >
                    <div>
                      <NewIcon
                        className=" w-8 h-8 bg-white m-0.5 border border-slate-400  rounded-lg"
                      />
                    </div>
                  </div>
                  <div
                    className={"flex w-full inline-flex w-full px-4 py-2 text-sm font-medium text-company-primary-color hover:bg-company-primary-color-100"
                      + (this.state.buttonActive === "New Products"
                        ? " bg-company-primary-color-100"
                        : " ")}
                  >
                    <p
                      className='text-left'
                    >
                      <Text tid="NewProducts" />
                    </p>
                  </div>
                </button>

                <button
                  onClick={() => this.handleOpen("PickedProducts", "Favorites")}
                  className="flex flex-row p-1 w-full"
                >
                  <div className="flex  w-10  justify-center p-1">
                    <div>
                      <FavIcon
                        className="text-company-secondary-color fill-company-secondary-color w-8 h-8 bg-white border
                      border-slate-400  rounded-lg"
                      />
                    </div>
                  </div>
                  <div
                    className={"flex w-full inline-flex w-full px-4 py-2 text-sm font-medium text-company-primary-color hover:bg-company-primary-color-100"
                      + (this.state.buttonActive === "Favorites"
                        ? " bg-company-primary-color-100"
                        : " ")}
                  >
                    <p className='text-left'>
                      <Text tid="Favorites" />
                    </p>
                  </div>
                </button>

                <button
                  onClick={() => this.handleOpen("PickedProducts", "Your Choice")}
                  className="flex flex-row p-1 w-full"
                >
                  <div className="flex w-10 justify-center p-1">
                    <div>
                      <TickIcon
                        className="p-0.5 text-company-primary-color fill-company-primary-color w-8 
                      h-8 bg-white border border-slate-400  rounded-lg"
                      />
                    </div>
                  </div>
                  <div
                    className={"flex w-full inline-flex w-full px-4 py-2 text-sm font-medium text-company-primary-color  hover:bg-company-primary-color-100"
                      + (this.state.buttonActive === "Your Choice"
                        ? " bg-company-primary-color-100"
                        : " ")}
                  >
                    <p className='text-left'>
                      <Text tid="YourChoice" />
                    </p>
                  </div>
                </button>

                <button
                  onClick={() => this.handleOpen("PickedProducts", "Discover")}
                  className="flex flex-row p-1 w-full"
                >
                  <div className="flex w-10 justify-center p-1">
                    <div>
                      <DiscoverIcon
                        className="p-0.5 text-company-primary-color fill-company-primary-color w-8 
                      h-8 bg-white border border-slate-400  rounded-lg"
                      />
                    </div>
                  </div>
                  <div
                    className={"flex w-full inline-flex w-full px-4 py-2 text-sm font-medium text-company-primary-color hover:bg-company-primary-color-100"
                      + (this.state.buttonActive === "Discover"
                        ? " bg-company-primary-color-100"
                        : " ")}
                  >
                    <p className='text-left'>
                      <Text tid="Discover" />
                    </p>
                  </div>
                </button>
              </div>

              {this.props.itemCategoryName && <div className="border-t"></div>}

              {
                this.props.itemCategoryName
                  ? this.props.itemCategoryName.map((item, index) => (
                    <div key={index}>
                      <button
                        onClick={() => this.handleOpen(item, null)}
                        className="flex flex-row p-1 w-full"
                      >
                        <div className="flex flex-row w-10  justify-center p-1" >
                          <div
                            className="flex flex-row w-10 h-8 rounded-lg bg-transparent "
                          >
                            <img
                              src={this.formatString(IMAGEURL + "/category/" + item + ".jpg")}
                              onError={(e) => {
                                e.currentTarget.onerror = null; // prevents looping
                                e.currentTarget.src = "noImage.jpg";
                              }} className="w-full h-full object-fill rounded-lg shadow-lg shadow-slate-950"
                              alt=''
                            />
                          </div>
                        </div>
                        <div
                          className={"flex w-full inline-flex w-full justify-between px-4 py-2 text-sm font-medium text-company-primary-color focus:bg-company-primary-color-100 hover:bg-company-primary-color-100"
                            + (this.state.test === item
                              ? " bg-company-primary-color-100"
                              : " ")}
                        >
                          <p className='text-left'>
                            {item}
                          </p>
                        </div>
                      </button>
                      {item !== "" &&
                        this.props.allCategories
                        ? this.props.allCategories.map((subCategory, index) => (
                          subCategory.itemCategoryName === item
                            ?
                            (
                              <button
                                onClick={() => this.handleOpen(null, subCategory.itemSubCategoryName)}
                                key={index}
                                className={"w-full inline-flex w-full justify-between px-4 py-2 text-sm font-medium text-company-primary-color  hover:bg-gray-50"
                                  + (this.state.test === subCategory.itemCategoryName && subCategory.itemSubCategoryName !== ""
                                    ? " flex"
                                    : " hidden")}
                              >
                                <ul>
                                  <li >
                                    <p className='text-right right-0 '>
                                      {subCategory.itemSubCategoryName}
                                    </p>
                                  </li>
                                </ul>
                              </button>
                            ) : null
                        ))
                        : null
                      }
                    </div>
                  )) : null
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CategoryList;