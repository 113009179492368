//import { LockClosedIcon } from '@heroicons/react/solid'
import React, { useEffect } from 'react'
import Counter from "./Counter";
import Favorite from "./Favorite";
import { ReactComponent as NewIcon } from './assets/pic/new.svg';
import { ReactComponent as DiscountIcon } from './assets/pic/discount.svg';
import { ReactComponent as TickIcon } from './assets/pic/tick.svg';
import roundDigits from './roundDigits';
import { Text } from './Language';
import fetchCampaignFromAPI from "./FetchCampaignFromAPI";
import {CURRENCY as NEWCURRENCY, DIGITS, IMAGEURL} from './components/Constants';

export default function ItemModal(props) {

    const [data, setData] = React.useState();

    useEffect(() => {
        fetchCampaignFromAPI(props.item.itemRef) //Fetch allCampaigns for a Product
            .then(json => {
                setData(json)
            })
    }, [props.item.itemRef]);

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed 
                inset-0 z-50 outline-none focus:outline-none"
            >
                <div
                    className=
                    {` ${sessionStorage.getItem('token')
                        ? "md:w-5/6 lg:w-5/6 xl:w-5/6 2xl:w-1/2 "
                        : "md:w-5/6 lg:w-5/6 xl:w-5/6 2xl:w-1/3"
                        }
                flex w-11/12 
                  `}
                >
                    {/*content*/}
                    <div
                        className="border-0 rounded-lg shadow-lg  bg-white outline-none focus:outline-none 
                    h-full flex flex-col"
                    >
                        {/*header*/}
                        <div
                            className="fade In flex items-center justify-between p-1 border-b 
                        border-solid border-slate-200 rounded-t"
                        >
                            <h3
                                className="text-lg font-semibold text-slate-700 p-1"
                            >
                                &#10140; {props.item.itemCategoryName}
                                &#10140; {props.item.itemSubCategoryName}
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent flex items-center border-0
                                 text-slate-900 opacity-50 float-right text-3xl leading-none 
                                 font-semibold outline-none focus:outline-none"
                                onClick={() => props.onSetModal(false)}
                            >
                                <span
                                    className="bg-transparent text-slate-900 h-6 w-6 text-lg block 
                                outline-none focus:outline-none items-center flex justify-center">
                                    &#10006;

                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div
                            className='flex flex-row  w-full justify-center p-1 text-xxs'
                        >
                            <div
                                className=" w-2/3"
                            >
                                <div
                                    className={
                                        ` ${props.item.itemAmount
                                            ? "border-company-primary-color"
                                            : "border-slate-300"} 
                                bg-white border-2 rounded-xl`
                                    }
                                    onClick={() => props.onSetModal(false)}
                                >
                                    <img
                                        alt=''
                                        src={IMAGEURL
                                            + "/catalog/"
                                            + props.item.itemCode
                                            + ".jpg"
                                        }
                                        onError={(e) => {
                                            e.currentTarget.onerror = null; // prevents looping
                                            e.currentTarget.src = "noImage.jpg";
                                        }
                                        }
                                        title={props.item.itemName1
                                        }
                                        className="object-cover p-1 "
                                    />
                                </div>
                            </div>

                            <div
                                className="w-1/3 bg-slate-200 rounded-xl p-1 text-xxs"
                            >
                                <div
                                    className="text-slate-900 flex-col flex w-full font-bold gap-4"
                                >
                                    <div
                                        className={` ${props.item.itemAmount
                                            ? "border-company-primary-color"
                                            : "border-slate-300"
                                            }
                                            text-xxs flex flex-row items-center p-1 justify-between 
                                            text-slate-400 w-24 h-7 bg-white border rounded-lg`
                                        }
                                    >
                                        <span>
                                            {props.item.itemCode}
                                        </span>
                                        <div
                                            className={
                                                props.item.itemOnHand === 0
                                                    ? "h-4 w-4 bg-red-500 rounded-full "
                                                    : "h-4 w-4 bg-green-500 rounded-full "
                                            }
                                        >
                                        </div>
                                    </div>
                                    <div >
                                        {props.item.itemName1}
                                    </div>
                                    <div className="text-slate-400">
                                        {props.item.itemName2}
                                    </div>
                                    <div>
                                        <Text tid="SalesUnit" />: {
                                            props.item.itemUnitCode
                                            + "("
                                            + props.item.itemMainUnitVpe
                                            + ")"
                                        }
                                    </div>
                                    {sessionStorage.getItem('token')
                                        ? <>
                                            <div
                                                className="text-slate-600"
                                            >
                                                <Text tid="VAT" />: % {props.item.itemVat}
                                            </div>
                                            <div
                                                className="flex flex-row items-center"
                                            >
                                                <Text tid="Price" />:
                                                <div
                                                    className={` ${props.item.itemCampaignPrice
                                                        ? "line-through"
                                                        : ""} font-bold text-company-primary-color px-4 text-xxs`
                                                    }
                                                >
                                                    {roundDigits(props.item.itemPrice.toFixed(DIGITS))}&nbsp; {NEWCURRENCY}
                                                </div>
                                                <div
                                                    className="flex flex-row items-center text-xxs"
                                                >

                                                    {props.item.itemCampaignPrice > 0
                                                        ?  <div className=" font-bold flex flex-row items-center text-red-600 shadow-md shadow-slate-400  w-26 h-7 bg-white m-0.5 border border-red-600  rounded-lg">
                                                                <span
                                                                    className='ml-1'
                                                                >
                                                                    {roundDigits(props.item.itemCampaignPrice.toFixed(DIGITS))
                                                                    }&nbsp;
                                                                    {NEWCURRENCY}
                                                                </span>
                                                                <DiscountIcon
                                                                    className=" ml-1 w-6 h-6"
                                                                />
                                                            </div>
                                                        :  null
                                                        
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className='h-1 bg-company-primary-color'
                                            >
                                            </div>
                                            <div
                                                className={` ${props.item.itemOnHand
                                                    ? "flex w-28 my-1 relative"
                                                    : "hidden"
                                                    }  `
                                                }
                                            >
                                                <Counter
                                                    item={props.item}
                                                    value={props.value}
                                                    handleChangeCount={props.handleChangeCount}
                                                    modal={true}
                                                    basket={true}
                                                    count={props.item.itemAmount}
                                                    onClickIncrement={() => props.onAdd(props.item)}
                                                    onClickDecrement={() => props.onRemove(props.item)}
                                                />
                                            </div>
                                        </>
                                        :null
                                    }
                                    <div
                                        className='flex flex-row relative items-center'
                                    >
                                        {sessionStorage.getItem('token')
                                            ? <Favorite
                                                className=""
                                                basket={true}
                                                itemRef={props.item.itemRef}
                                                itemFavorite={props.item.itemFavorite}
                                                item={props.item}
                                                onAddAllFavorites={props.onAddAllFavorites}
                                                onRemoveAllFavorites={props.onRemoveAllFavorites}
                                            />
                                            :null
                                        }
                                        {props.item.itemNew === 1
                                            ? <NewIcon className=" w-7 h-7 bg-white m-0.5 border border-slate-400
                                             shadow-slate-500 rounded-lg shadow-2xl"
                                            />
                                            :null
                                        }
                                        {props.item.itemSold === 1
                                            ? <div
                                                className={` ${props.item.itemAmount
                                                    ? "border-company-primary-color"
                                                    : "border-slate-300"
                                                    }  
                                            flex flex-row items-center justify-between text-company-primary-color w-7 h-7 
                                            bg-white border rounded-lg`}
                                            >
                                                <TickIcon />
                                            </div>
                                            :null
                                        }
                                        {props.item.itemCampaign === 1
                                            ? <div
                                            className=" font-bold flex flex-row items-center 
                                        text-red-600 shadow-md shadow-slate-400 
                                        w-26 h-7 bg-white m-0.5 border 
                                        border-red-600  rounded-lg">
                                            
                                            <DiscountIcon
                                                className=" m-1 w-6 h-6"
                                            />
                                        </div> 
                                        :null
                                        }
                                    </div>
                                    <div>
                                        {
                                            data && data.dataSet
                                   
                                            ? <div
                                                className='flex flex-row w-full overflow-auto'
                                            >
                                                <table
                                                    className='overflow-auto w-full border-separate  
                                                        border border-slate-400 text-center table-auto'
                                                >
                                                    <thead className='sticky top-0 bg-white'>
                                                        <tr>
                                                            <th className="border border-slate-300"><Text tid="Preis" /></th>
                                                            <th className="border border-slate-300">%</th>
                                                            <th className="border border-slate-300"><Text tid="Min" /></th>
                                                            <th className="border border-slate-300"><Text tid="Max" /></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='overflow-y-auto '>
                                                        {
                                                            data
                                                            && data.dataSet?.map((item, itemIndex) => (
                                                                <tr
                                                                    key={itemIndex}
                                                                    className="odd:bg-white even:bg-slate-200 text-xxs"
                                                                >

                                                                    <td className=''>
                                                                        {item.CampaignPrice}
                                                                    </td>
                                                                    <td className=''>
                                                                        {
                                                                            ((props.item.itemPrice - item.CampaignPrice) /
                                                                                (props.item.itemPrice / 100)).toFixed(0)
                                                                        }
                                                                    </td>
                                                                    <td className=''>
                                                                        {item.MinAmount}
                                                                    </td>
                                                                    <td className=''>
                                                                        {item.MaxAmount}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                    :null    
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="opacity-50 fixed inset-0 z-40 bg-black"
            >
            </div>
        </>
    )
}
