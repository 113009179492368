import React from "react";
import { ReactComponent as FavIcon } from './assets/pic/heart.svg';

export default class Favorite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {     
    };
  }

  onClick= () => {
   if (this.props.itemFavorite === 0) {
    this.props.onAddAllFavorites(this.props.item)
    }else{
      this.props.onRemoveAllFavorites(this.props.item)
    }
  };
  
  render() {
    return (
        <button onClick={() => this.onClick()}
        className={`${this.props.itemFavorite ? "text-company-secondary-color fill-company-secondary-color" : "fill-white stroke-company-secondary-color"} ${this.props.basket ? "h-7 self-center" : "absolute top-8 left-5"} bg-white m-0.5 border border-slate-400 shadow-slate-500 rounded-lg shadow-2xl`}
        >
      <FavIcon className="w-6 h-6"/>    
    </button>
    );
  }
}