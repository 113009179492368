import React, { Component } from "react";
//import { data } from "autoprefixer";
//import e from "cors";
import Counter from "./Counter";
import Favorite from "./Favorite";
import BlankBasket from "./BlankBasket";
import DetailedBasket from "./DetailedBasket";
import { ReactComponent as DiscountIcon } from './assets/pic/discount.svg';
import Loading from "./Loading";
import { Text } from './Language';
import { CURRENCY as NEWCURRENCY, DIGITS, IMAGEURL } from './components/Constants';
import roundDigits from "./roundDigits";
import { ReactComponent as CartIcon } from './assets/pic/cart.svg';

class Korb extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const discRate = sessionStorage.getItem('discRate');
    const itemsPrice = this.props.cartItems?.reduce((a, c) =>
      a + c.itemMainUnitVpe
      * (c.itemCampaignPrice ? c.itemCampaignPrice?.toFixed(DIGITS) : c.itemPrice?.toFixed(DIGITS))
      * (c.itemCampaignPrice ? 1 : (1 - (!Number.isNaN(discRate) && discRate>0 ? discRate / 100 : 0)))
      * c.itemAmount, 0);

    return (
      <>
        <div
          className="w-[40rem] flex flex-col pointer-events-auto pl-6 h-full hidden lg:flex"
        >
          <h2
            className="text-lg font-medium text-company-primary-color my-2"
          >
            <CartIcon className="w-12 h-6 fill-company-primary-color" />
          </h2>
          <div
            className="flex  w-full flex-col border-t-8 border-b-8 border-r-2 border-company-primary-color overflow-auto"
          >
            {
              this.props.cartItems?.length !== 0
                && sessionStorage.getItem('token')
                && itemsPrice
                ? (
                  <div
                    className="flex flex-col"
                  >
                    <div
                      className="flex flex-row sticky top-0 z-30 bg-white border-b border-slate-200 p-2 w-full justify-between"
                    >                      
                        <DetailedBasket
                        ShowDetailedBasket={(value) => this.props.ShowDetailedBasket(value)}                          
                        />                   
                      <div
                        className="flex gap-2 text-base font-medium text-company-primary-color"
                      >
                        <p>
                          <Text tid="Total" />
                        </p>
                        <p>
                          {
                            roundDigits(itemsPrice?.toFixed(DIGITS))

                          }	&nbsp;
                          {NEWCURRENCY}
                        </p>
                      </div>
                    </div>
                    <div
                      className=""
                    >
                      <div
                        className="flow-root p-1"
                      >
                        <ul
                          className="flex flex-col  divide-y divide-gray-200 overflow-auto h-full"
                        >
                          {
                            this.props.cartItems
                            && this.props.cartItems?.map((record, index) => {
                              return (
                                <li
                                  className="flex flex-row relative justify-between items-center"
                                  key={index}
                                >
                                  <div
                                    className=" relative w-1/6 h-12 overflow-hidden rounded-md border border-slate-200 flex item-center"
                                  >
                                    <img
                                      alt=""
                                      src={IMAGEURL + "/thumbs/TH" + record.itemCode + ".jpg"}
                                      onError={(e) => {
                                        e.currentTarget.onerror = null; // prevents looping
                                        e.currentTarget.src = "noImage.jpg";
                                      }
                                      }
                                      className="p-1 w-full h-full object-contain "
                                    />
                                    {record.itemCampaign
                                      ? <div
                                        className="flex flex-row items-center text-red-600 absolute bottom-0 right-0 w-5 h-5"
                                      >
                                        <DiscountIcon
                                          className="w-5 h-5"
                                        />
                                      </div>
                                      : null
                                    }
                                  </div>
                                  <div
                                    className="flex flex-col text-very-small text-slate-500 justify-start w-3/4 m-1"
                                  >
                                    <p>
                                      {record.itemName1}
                                    </p>
                                    <div
                                      className="flex flex-row justify-between"
                                    >
                                      <p>
                                        {record.itemName2}
                                      </p>
                                      <p
                                        className="text-red-600 ml-5"
                                      >
                                        {record.itemCode}
                                      </p>
                                      <div
                                        className="flex flex-row items-center"
                                      >
                                        <div
                                          className={
                                            record.itemOnHand === 0
                                              ? "h-2 w-2 bg-red-500 rounded-full mr-0.5"
                                              : "h-2 w-2 bg-green-500 rounded-full mr-0.5"
                                          }
                                        >
                                        </div>
                                        <div>
                                          {record.itemUnitCode + "(" + record.itemMainUnitVpe + ")"}
                                        </div>
                                      </div>
                                    </div>   
                                      {
                                        record.itemCampaignPrice > 0
                                          ? <div className="flex flex-row justify-between w-full font-bold text-xxs text-red-600">
                                            <div className={`${record.itemCampaignPrice
                                              ? "line-through"
                                              : ""
                                              } text-company-primary-color`}>
                                              {roundDigits(record.itemPrice.toFixed(DIGITS))} {NEWCURRENCY}
                                            </div>
                                            <div>
                                              {roundDigits(record.itemCampaignPrice.toFixed(DIGITS))} {NEWCURRENCY}
                                            </div>
                                          </div>
                                          : sessionStorage.getItem("discRate")
                                            ? <div className="flex flex-row justify-between w-full font-bold text-xxs text-company-primary-color">
                                              <div className="flex flex-row">
                                                {roundDigits(record.itemPrice.toFixed(DIGITS))} {NEWCURRENCY}
                                              </div>
                                              <div className="text-red-600">{sessionStorage.getItem("discRate") > 0 ? JSON.parse(sessionStorage.getItem("discRate"))+ '%' : null}</div>
                                              <div className="text-red-600 justify-between flex flex-row">   
                                              </div>
                                            </div>
                                            : <div className="flex flex-row justify-between w-full font-bold text-xxs text-company-primary-color">
                                              <div className="flex flex-row">
                                                {roundDigits(record.itemPrice.toFixed(DIGITS))} {NEWCURRENCY}
                                              </div>
                                            </div>
                                      }                                 
                                  </div>
                                  <div
                                    className="flex"
                                  >
                                    <Favorite
                                      className="shadow-md shadow-slate-400"
                                      basket={true}
                                      itemRef={record.itemRef}
                                      itemFavorite={record.itemFavorite}
                                      item={record}
                                      onAddAllFavorites={this.props.onAddAllFavorites}
                                      onRemoveAllFavorites={this.props.onRemoveAllFavorites}
                                    />
                                  </div>
                                  <div
                                    className="w-28"
                                  >
                                    <Counter
                                      basket={true}
                                      count={record.itemAmount}
                                      onClickIncrement={() => this.props.onAdd(record)}
                                      onClickDecrement={() => this.props.onRemove(record)}
                                    />
                                  </div>
                                </li>
                              );
                            }
                            )
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                )
                :
                (!sessionStorage.getItem('slipRef')
                  && sessionStorage.getItem('token')
                  ? <div
                    className="flex w-full items-center justify-center"
                  >
                    <Loading />
                  </div>
                  :
                  <div
                    className="h-96"
                  >
                    <BlankBasket />
                  </div>)
            }
          </div>
        </div>
      </>
    );
  }
}

export default Korb;