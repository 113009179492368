import React from 'react'
import { Text } from './Language';

export default function Datenschutz(props) {
   
    function openDatenschutz() {
        console.log("hi")
        props.ShowDatenschutz(true)
       
    };
    

    return (
        <>
            <button
                className="flex items-center 
        justify-center "
                type="button"
                onClick={() => openDatenschutz()}
            >
                <Text tid="Datenschutz" />
            </button>
            
        </>
    )
}