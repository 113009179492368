import React from 'react'
import Counter from "./Counter";
import Favorite from "./Favorite";
import { ReactComponent as NewIcon } from './assets/pic/new.svg';
import { ReactComponent as DiscountIcon } from './assets/pic/discount.svg';
import { ReactComponent as TickIcon } from './assets/pic/tick.svg';
import roundDigits from './roundDigits';
import ItemModal from './ItemModal';
import { CURRENCY as NEWCURRENCY, DIGITS, IMAGEURL, FLAG_ITEM_ON_HAND } from './components/Constants';

export default function Item(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [url] = React.useState("noImage.jpg");
    const [imgurl] = React.useState(IMAGEURL+ "/thumbs/TH");

    if (!props.item)
        return null;

    return (
        <>
            {showModal ?
                (<ItemModal
                    value={props.value}
                    handleChangeCount={props.handleChangeCount}
                    count={props.item.itemAmount}
                    onAdd={props.onAdd}
                    onRemove={props.onRemove}
                    item={props.item}
                    onSetModal={setShowModal}
                    onAddAllFavorites={props.onAddAllFavorites}
                    onRemoveAllFavorites={props.onRemoveAllFavorites}
                    itemRef={props.item.itemRef}
                    itemFavorite={props.item.itemFavorite}
                />
                )
                : null
            }
            <div
                className="relative w-24 sm:w-48 p-4 hover:bg-gray-100 "
            >
                <div
                    className={` 
                ${props.item.itemAmount
                            ? "border-company-secondary-color"
                            : "border-slate-300"
                        } 
                bg-white  flex flex-wrap flex-col border-2 rounded-xl 
                `}
                >
                    <div
                        className="flex-col flex w-20  sm:w-40 p-1 justify-center"
                    >
                        <div
                            className="flex h-20 sm:h-40 rounded bg-white"
                        >
                            <img
                                alt=""
                                onClick={() => setShowModal(true)}
                                src={imgurl
                                    + props.item.itemCode
                                    + ".jpg"}
                                onError={(e) => {
                                    e.currentTarget.onerror = null; // prevents looping
                                    e.currentTarget.src = url;
                                }}
                                title={props.item.itemName1}
                                className="p-1 w-full h-full object-contain"
                            />
                            <div
                                className="shadow-md shadow-slate-400"
                            >
                                {sessionStorage.getItem('token')
                                    ? <Favorite
                                        className="shadow-md shadow-slate-400"
                                        basket={false}
                                        itemRef={props.item.itemRef}
                                        itemFavorite={props.item.itemFavorite}
                                        item={props.item}
                                        onAddAllFavorites={props.onAddAllFavorites}
                                        onRemoveAllFavorites={props.onRemoveAllFavorites}
                                    />
                                    : null
                                }
                            </div>
                            {props.item.itemNew === 1
                                ? <div className="shadow-md shadow-slate-400">
                                    {sessionStorage.getItem('token')
                                        ? <NewIcon
                                            className="absolute top-[153px] right-2 w-8 h-8 bg-white
                                     m-0.5 border border-slate-400 shadow-slate-500 
                                     rounded-lg shadow-2xl"
                                        />
                                        : null
                                    }
                                </div>
                                : null
                            }



                            {
                                sessionStorage.getItem('token') &&
                                    props.item.itemCampaignPrice > 0
                                    ? <div
                                        className=" font-bold flex flex-row items-center 
                                                text-red-600 shadow-md shadow-slate-400 absolute
                                                top-20 sm:top-40 left-2 sm:w-26 h-7 bg-white m-0.5 border 
                                                border-red-600  rounded-lg">
                                        <span
                                            className='ml-1'
                                        >
                                            {roundDigits(props.item.itemCampaignPrice?.toFixed(DIGITS))} {NEWCURRENCY}
                                        </span>
                                        <DiscountIcon
                                            className=" ml-1 w-6 h-6"
                                        />
                                    </div>
                                    : props.item.itemCampaign
                                        ? <div
                                            className=" font-bold flex flex-row items-center 
                                        text-red-600 shadow-md shadow-slate-400 absolute
                                        top-40 left-2 w-26 h-7 bg-white m-0.5 border 
                                        border-red-600  rounded-lg">

                                            <DiscountIcon
                                                className=" m-1 w-6 h-6"
                                            />
                                        </div> : null
                            }


                            <div
                                className={` 
                            ${props.item.itemAmount
                                        ? "border-company-secondary-color"
                                        : "border-slate-300"
                                    }
                                    absolute left-2 top-0 flex flex-row items-center p-1 justify-between
                                    text-slate-400 w-20 h-7 bg-white border rounded-lg`
                                }
                            >
                                <span
                                    className=''
                                >
                                    {props.item.itemCode}
                                </span>
                                <div
                                    className={
                                        props.item.itemOnHand === 0
                                            ? "h-2 w-2 bg-red-500 rounded-full "
                                            : "h-2 w-2 bg-green-500 rounded-full "
                                    }
                                ></div>
                            </div>
                            {sessionStorage.getItem('token')
                                && props.item.itemSold === 1
                                ? <div className={` 
                                ${props.item.itemAmount
                                        ? "border-company-secondary-color"
                                        : "border-slate-300"
                                    } 
                                absolute right-12 top-0 flex flex-row items-center p-1 justify-between 
                                text-company-primary-color w-7 h-7 bg-white border rounded-lg`
                                }
                                >
                                    <TickIcon/>
                                </div>
                                : null
                            }
                            <div
                                className={` 
                            ${!props.item.itemOnHand && FLAG_ITEM_ON_HAND
                                        ? "hidden"
                                        : ""} 
                            `}
                            >
                                {sessionStorage.getItem('token')
                                    ? <Counter
                                        basket={false}
                                        count={props.item.itemAmount}
                                        onClickIncrement={() => props.onAdd(props.item)}
                                        onClickDecrement={() => props.onRemove(props.item)}
                                    />
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="flex py-3 px-1 justify-between"
                >
                    {sessionStorage.getItem('token')
                        ? <div className={` 
                        ${props.item.itemCampaignPrice
                                ? "line-through"
                                : ""
                            } font-bold text-company-primary-color 
                        `}
                        >
                            {props.item.itemPrice
                                ? <div> {roundDigits(props.item.itemPrice.toFixed(DIGITS))} {NEWCURRENCY}</div>
                                : null}
                        </div>
                        : null
                    }
                    <div
                        className="flex flex-row items-center text-xxs"
                    >
                        <div>
                            {props.item.itemUnitCode
                                + "(" + props.item.itemMainUnitVpe
                                + ")"}
                        </div>
                    </div>
                </div>
                <div
                    className=" flex-col flex grow w-full justify-between text-xxs"
                >
                    <div>
                        {props.item.itemName1}
                    </div>
                    <div
                        className="text-slate-400"
                    >
                        {props.item.itemName2}
                    </div>
                </div>
            </div>
        </>
    )
}
