import React from 'react'
import { Text } from './Language';

function DetailedBasket(props) {

    return (      
            <div>
                <button
                    onClick={() => props.ShowDetailedBasket(true)}
                    className="flex w-28 lg:w-40 items-center justify-center rounded-md 
                border border-transparent bg-company-primary-color px-1 py-1 text-base font-medium
                 text-white shadow-sm hover:opacity-75"
                >
                    <Text tid="ShowOrder" />
                </button>
            </div>  
    )
}

export default DetailedBasket