import React from 'react'
import { Text } from './Language'

export default function ShowAGB(props) {
    return (<>
        {props.agb ? (

            <>
                <div
                    className="text-slate-600 w-full h-full fade In justify-center items-center flex overflow-hidden absolute top-1 fixed inset-0 z-50 
outline-none focus:outline-none"
                >
                    <div
                        className="relative w-full h-full p-6"
                    >
                        {/*content*/}
                        <div
                            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none"
                        >
                            {/*header*/}
                            <div
                                className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                            >
                                <h3
                                    className="text-lg font-semibold"
                                >
                                    <Text tid="AGB" />
                                </h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-50 float-right text-3xl 
      leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => props.ShowAGB(false)}
                                >
                                    <span
                                        className="bg-transparent text-black h-6 w-6 text-xl block outline-none focus:outline-none"
                                    >
                                        &#10006;
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div
                                className="relative p-6 flex-auto w-full"
                            >
                                   <iframe title="Conditions" className="responsive-iframe" src="pages/conditions.html"></iframe>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="opacity-50 fixed inset-0 z-40 bg-black"
                >
                </div>
            </>

        )
            : null
        }
    </>
    )
}
