//import {CURRENCY as NEWCURRENCY} from './components/Constants';

export default function toGermanFloat(number) {
   // if (NEWCURRENCY === 'CHF') return number;

    if(number){
    var negative = "";
    if (number?.includes('-')) {
        number=number.replace('-', '')
        negative = "-";    
    }
    number = number?.toString().split('.');
    number[0] = '' + number[0];
    if (number[0].length > 3) {
        var mod = number[0].length % 3;
        var output = (mod > 0 ? (number[0].substring(0, mod)) : '');
        for (var i = 0; i < Math.floor(number[0].length / 3); i++) {
            if ((mod === 0) && (i === 0))
                output += number[0].substring(mod + 3 * i, mod + 3 * i + 3);
            else
            // hier wird das Trennzeichen festgelegt mit '.'
            { output += '.' + number[0].substring(mod + 3 * i, mod + 3 * i + 3); }
        }
        return (output = negative + output + ',' + number[1]);
    }
    else return number[0] = negative + number[0] + ',' + number[1];
}
else return "";

}
