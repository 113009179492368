import React from 'react'
import { Text } from './Language';
import { URL as NEWURL } from './components/Constants';
import { VENDORID as NEWVENDORID } from './components/Constants';

export default function OrderItems(props) {

    const sendOrder = () => {

        const url = NEWURL;

        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json' },
            body: JSON.stringify({
                "serviceId": 2018, //Slip Send
                "token": sessionStorage.getItem('token'),
                "vendorId": NEWVENDORID,
                "slipRef": sessionStorage.getItem('slipRef'),
                "erpGenExpl1": sessionStorage.getItem('Expl1'),
                "erpGenExpl2": sessionStorage.getItem('Expl2')
            })
        };

        const fetchData = async () => {
            try {
                const response = await fetch(url, requestOptions);
                const json = await response.json();

                if (json.resCode === 200) {
                    sessionStorage.setItem('slipRef', json.dataSet[0]?.slipRef);
                    sessionStorage.removeItem('Expl1');
                    sessionStorage.removeItem('Expl2');

                    props.onSlipDeleteAllLines()
                    props.showToast('Sent')
                }
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();

    }

    return (
        <div>
            <button onClick={() => sendOrder()} className="flex w-28 lg:w-32 items-center 
                justify-center rounded-md border border-transparent bg-company-primary-color px-1 py-1 
                text-base font-medium text-white shadow-sm hover:opacity-75">
                <Text tid="SendOrder" />
            </button>
        </div>
    )
}
