import React from 'react'
import { ReactComponent as BasketIcon } from './assets/pic/basket.svg';
import { Text } from './Language';

export default function BlankBasket() {
  return (
    <div className="flex flex-col self-center w-full text-center items-center h-full items-center py-24">
        <div className='text-company-primary-color fill-company-primary-color stroke-company-primary-color'><BasketIcon/></div>
        <div className='text-company-primary-color'><Text tid="EmptyShoppingCart" /></div>
        <div className='py-2'><Text tid="AddToOrder" /></div>
    </div>
  )
}
